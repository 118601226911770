import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TenantService } from '../../../core/services/tenant.service';
import { CreateTenantRequest } from '../../../core/models/request/create-tenant-request';

@Component({
  selector: 'app-create-realm-dialog',
  templateUrl: './create-realm-dialog.component.html',
  styleUrls: ['./create-realm-dialog.component.scss'],
  providers: [TenantService],
})
export class CreateRealmDialogComponent {
  settingsFormGroup = new FormGroup({
    realmAndTenantIdentifier: new FormControl('', Validators.required),
    displayName: new FormControl('', Validators.required),
    adminUsername: new FormControl('', Validators.required),
    adminPassword: new FormControl('', Validators.required),
    adminEmail: new FormControl('', [Validators.required, Validators.email]),
    adminFirstName: new FormControl('', Validators.required),
    adminLastName: new FormControl('', Validators.required),
  });

  requiredErrorMessage = 'Dieses Feld darf nicht leer sein.';
  isLoading = false;

  constructor(
    private readonly tenantService: TenantService,
    private readonly dialogRef: MatDialogRef<CreateRealmDialogComponent>,
  ) {}

  createRealmClicked() {
    this.isLoading = true;
    let request = {
      identifier:
        this.settingsFormGroup.controls.realmAndTenantIdentifier.value,
      name: this.settingsFormGroup.controls.displayName.value,
      realmName: this.settingsFormGroup.controls.realmAndTenantIdentifier.value,
      adminUsername: this.settingsFormGroup.controls.adminUsername.value,
      adminPassword: this.settingsFormGroup.controls.adminPassword.value,
      adminEmail: this.settingsFormGroup.controls.adminEmail.value,
      adminFirstName: this.settingsFormGroup.controls.adminFirstName.value,
      adminLastName: this.settingsFormGroup.controls.adminLastName.value,
    } as CreateTenantRequest;

    this.tenantService.createNewTenant(request).subscribe({
      next: (result) => {
        this.dialogRef.close(result.realmName);
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  getEmailErrorMessage() {
    if (this.settingsFormGroup.controls.adminEmail.hasError('required')) {
      return this.requiredErrorMessage;
    }

    return this.settingsFormGroup.controls.adminEmail.hasError('email')
      ? 'Keine gültige E-Mail Adresse'
      : 'Unbekannter Fehler';
  }
}
