import { Component } from '@angular/core';
import { StaticTemplateService } from '../../../api/gen';

@Component({
  selector: 'app-static-template-overview',
  templateUrl: './static-template-overview.component.html',
  styleUrls: ['./static-template-overview.component.scss'],
})
export class StaticTemplateOverviewComponent {
  constructor(private readonly staticTemplateService: StaticTemplateService) {
    this.staticTemplateService
      .internalStaticTemplateDistinctGet()
      .subscribe((result) => {
        console.log(result);
      });
  }
}
