import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { SubscriptionType } from '../../../core/models/subscription';
import { CreateSubscriptionInternalRequest } from '../../../core/models/request/create-subscription-internal-request';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentInterval, PaymentProvider } from '../../../../api/gen';
import { FeatureConfigurationCreation } from '../../../core/models/feature-configuration-creation';

@Component({
  selector: 'app-create-subscription-dialog',
  templateUrl: './create-subscription-dialog.component.html',
  styleUrls: ['./create-subscription-dialog.component.scss'],
  providers: [SubscriptionService],
})
export class CreateSubscriptionDialogComponent {
  settingsFormGroup = new FormGroup({
    subscriptionType: new FormControl('', Validators.required),
    numberOfUsers: new FormControl(''),
    deleteDataAfterDays: new FormControl(''),
  });

  requiredErrorMessage = 'Dieses Feld darf nicht leer sein.';
  isLoading = false;

  constructor(
    private readonly dialogRef: MatDialogRef<CreateSubscriptionDialogComponent>,
    private readonly snackBar: MatSnackBar,
    private readonly subscriptionService: SubscriptionService,
    @Inject(MAT_DIALOG_DATA) public data: CreateSubscriptionDialogData,
  ) {}

  createSubscriptionClicked() {
    const request = {
      tenantId: this.data.tenantId,
      externalIdentifier: self.crypto.randomUUID(),
      paymentProvider: PaymentProvider.None,
      paymentInterval: PaymentInterval.None,
      subscriptionType: this.settingsFormGroup.controls.subscriptionType.value,
      billingInformation: null,
      referrer: null,
      featureConfigurationCreation: this.getFeatureConfigurationCreation(),
    } as CreateSubscriptionInternalRequest;
    this.subscriptionService
      .createSubscription(this.data.tenantId, request)
      .subscribe((result) => {
        this.snackBar.open(
          `Created subscription with id = ${result.id}`,
          'Ok',
          { duration: 5000 },
        );
        this.dialogRef.close();
      });
  }

  protected readonly SubscriptionType = SubscriptionType;
  subscriptionTypes = [
    new SubscriptionTypeInput(SubscriptionType.Trial, 'Trial'),
    new SubscriptionTypeInput(SubscriptionType.Starter, 'Starter'),
    new SubscriptionTypeInput(SubscriptionType.Pro, 'Pro'),
    new SubscriptionTypeInput(SubscriptionType.Ultimate, 'Ultimate'),
    new SubscriptionTypeInput(SubscriptionType.Individual, 'Individual'),
  ];

  private getFeatureConfigurationCreation(): FeatureConfigurationCreation {
    if (
      this.settingsFormGroup.controls.subscriptionType.value ===
      SubscriptionType.Individual
    ) {
      return {
        numberOfUsersIncluded: parseInt(
          this.settingsFormGroup.controls.numberOfUsers.value,
        ),
        deleteDataAfterDays: parseInt(
          this.settingsFormGroup.controls.deleteDataAfterDays.value,
        ),
      } as FeatureConfigurationCreation;
    }

    return null;
  }
}

export class CreateSubscriptionDialogData {
  tenantId: string;
}

export class SubscriptionTypeInput {
  constructor(type: SubscriptionType, displayName: string) {
    this.type = type;
    this.displayName = displayName;
  }
  type: SubscriptionType;
  displayName: string;
}
