import { Component, effect, Input, OnInit, signal } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MatCard,
  MatCardContent,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle,
} from '@angular/material/card';
import { Tenant } from '../../../core/models/tenant';
import { FeatureConfigurationService } from '../../../core/services/feature-configuration.service';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrandingColorService } from '../../../core/services/branding-color.service';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { BrandingColor } from 'aidar-branding';

@Component({
  selector: 'app-tenant-branding-color',
  standalone: true,
  imports: [
    MatButton,
    MatCard,
    MatCardHeader,
    MatCardContent,
    MatCardSubtitle,
    MatCardTitle,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatLabel,
  ],
  templateUrl: './tenant-branding-color.component.html',
  styleUrl: './tenant-branding-color.component.scss',
  providers: [BrandingColorService, FeatureConfigurationService],
})
export class TenantBrandingColorComponent implements OnInit {
  @Input() tenant: Tenant;

  protected isDisabled = signal(true);
  protected brandingColor = signal<BrandingColor | null>(null);

  brandingColorFormGroup = this.formBuilder.group({
    primaryColor: new FormControl('', [Validators.required]),
    accentColor: new FormControl('', [Validators.required]),
    warnColor: new FormControl('', [Validators.required]),
  });

  constructor(
    private readonly brandingColorService: BrandingColorService,
    private readonly featureConfigurationService: FeatureConfigurationService,
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
  ) {
    effect(() => {
      if (this.brandingColor()) {
        this.brandingColorFormGroup.controls.primaryColor.setValue(
          this.brandingColor().primaryColorHex,
        );
        this.brandingColorFormGroup.controls.accentColor.setValue(
          this.brandingColor().accentColorHex,
        );
        this.brandingColorFormGroup.controls.warnColor.setValue(
          this.brandingColor().warnColorHex,
        );
      }
    });
  }

  ngOnInit(): void {
    this.reload();
  }

  public reload() {
    this.featureConfigurationService
      .getActiveFeatureConfigurationForTenant(this.tenant.id)
      .subscribe((brandingColor) => {
        if (brandingColor.corporateIdentityConfiguration.canEditCompanyColors) {
          this.isDisabled.set(false);
          this.brandingColorService
            .getBrandingColorForTenant(this.tenant.id)
            .subscribe((brandingColor) => {
              this.brandingColor.set(brandingColor);
            });
        } else {
          this.isDisabled.set(true);
        }
      });
  }

  onUpdateBrandingColor() {
    this.brandingColorService
      .createOrUpdateBrandingColor(
        this.tenant.id,
        this.brandingColorFormGroup.controls.primaryColor.value,
        this.brandingColorFormGroup.controls.accentColor.value,
        this.brandingColorFormGroup.controls.warnColor.value,
      )
      .subscribe((res) => {
        this.snackBar.open(`Branding color updated.`, 'Ok', { duration: 3000 });
      });
  }
}
