<app-heading-container
  title="Anfragen Übersicht"
  subTitle="Hier können Sie Informationen über alle Anfragen der letzen Tage sehen"
>
  <!--<button mat-flat-button color="primary" (click)="onCreateNewRealmClicked()">
    Neuen Realm anlegen
  </button>-->
</app-heading-container>

<mat-card>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="tenantId">
      <th mat-header-cell *matHeaderCellDef>Tenant Id</th>
      <td mat-cell *matCellDef="let element">{{ element?.tenantId }}</td>
    </ng-container>

    <ng-container matColumnDef="tenantIdentifier">
      <th mat-header-cell *matHeaderCellDef>Tenant Identifier</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.tenantIdentifier }}
      </td>
    </ng-container>

    <ng-container matColumnDef="tenantName">
      <th mat-header-cell *matHeaderCellDef>Tenant Name</th>
      <td mat-cell *matCellDef="let element">{{ element?.tenantName }}</td>
    </ng-container>

    <ng-container matColumnDef="tenantConnectionString">
      <th mat-header-cell *matHeaderCellDef>Tenant Conn-Str.</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.tenantConnectionString }}
      </td>
    </ng-container>

    <ng-container matColumnDef="realmName">
      <th mat-header-cell *matHeaderCellDef>Realm Name</th>
      <td mat-cell *matCellDef="let element">{{ element?.realmName }}</td>
    </ng-container>

    <ng-container matColumnDef="inquiryLastDay">
      <th mat-header-cell *matHeaderCellDef>1d</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.inquiryLastDay }}
      </td>
    </ng-container>

    <ng-container matColumnDef="inquiryLastWeek">
      <th mat-header-cell *matHeaderCellDef>7d</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.inquiryLastWeek }}
      </td>
    </ng-container>

    <ng-container matColumnDef="inquiryLastMonth">
      <th mat-header-cell *matHeaderCellDef>31d</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.inquiryLastMonth }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-card>
