import { FeatureConfigurationCreationDto } from '../../../api/gen';

export class FeatureConfigurationCreation {
  numberOfUsersIncluded?: number;
  deleteDataAfterDays?: number;

  static fromDto(
    dto: FeatureConfigurationCreationDto,
  ): FeatureConfigurationCreation {
    return {
      numberOfUsersIncluded: dto.numberOfUsersIncluded,
      deleteDataAfterDays: dto.deleteDataAfterDays,
    } as FeatureConfigurationCreation;
  }

  static toDto(
    m: FeatureConfigurationCreation,
  ): FeatureConfigurationCreationDto {
    return {
      numberOfUsersIncluded: m.numberOfUsersIncluded,
      deleteDataAfterDays: m.deleteDataAfterDays,
    } as FeatureConfigurationCreationDto;
  }
}
