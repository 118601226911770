import { Component, Input, OnInit } from '@angular/core';
import { TenantService } from '../../../core/services/tenant.service';
import { Tenant } from '../../../core/models/tenant';

@Component({
  selector: 'app-tenant-info',
  templateUrl: './tenant-info.component.html',
  styleUrls: ['./tenant-info.component.scss'],
  providers: [TenantService],
})
export class TenantInfoComponent implements OnInit {
  @Input() tenant: Tenant;

  displayedColumns: string[] = [
    'id',
    'identifier',
    'name',
    'connectionString',
    'isStandalone',
    'realmName',
  ];
  dataSource: Tenant[];

  ngOnInit(): void {
    this.dataSource = [this.tenant];
  }
}
