import { BillingAddress } from './billing-address';
import { BillingInformationDto } from '../../../api/gen';

export class BillingInformation {
  name?: string | null;
  eMail?: string | null;
  phoneNumber?: string | null;
  sendTextNotificationIfPaymentFails?: boolean;
  address?: BillingAddress;

  static fromDto(dto: BillingInformationDto): BillingInformation {
    return {
      name: dto.name,
      eMail: dto.eMail,
      phoneNumber: dto.phoneNumber,
      sendTextNotificationIfPaymentFails:
        dto.sendTextNotificationIfPaymentFails,
      address: BillingAddress.fromDto(dto.address),
    } as BillingInformation;
  }

  static toDto(m: BillingInformation): BillingInformationDto {
    return {
      name: m.name,
      eMail: m.eMail,
      phoneNumber: m.phoneNumber,
      sendTextNotificationIfPaymentFails: m.sendTextNotificationIfPaymentFails,
      address: BillingAddress.toDto(m.address),
    } as BillingInformationDto;
  }
}
