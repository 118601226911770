import { BillingAddressDto } from '../../../api/gen';

export class BillingAddress {
  firstAddressLine?: string | null;
  secondAddressLine?: string | null;
  locality?: string | null;
  postCode?: string | null;
  region?: string | null;
  country?: string | null;

  static fromDto(dto: BillingAddressDto): BillingAddress {
    return {
      firstAddressLine: dto.firstAddressLine,
      secondAddressLine: dto.secondAddressLine,
      locality: dto.locality,
      postCode: dto.postCode,
      region: dto.region,
      country: dto.country,
    } as BillingAddress;
  }

  static toDto(m: BillingAddress): BillingAddressDto {
    return {
      firstAddressLine: m.firstAddressLine,
      secondAddressLine: m.secondAddressLine,
      locality: m.locality,
      postCode: m.postCode,
      region: m.region,
      country: m.country,
    } as BillingAddressDto;
  }
}
