import { Component, OnInit } from '@angular/core';
import { CompanyAndTenantInfo } from '../../core/models/company-and-tenant-info';
import { CompanyClientService } from '../../core/services/company-client.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateRealmDialogComponent } from '../dialogs/create-realm-dialog/create-realm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteRealmDialogComponent } from '../dialogs/delete-realm-dialog/delete-realm-dialog.component';

@Component({
  selector: 'app-realms-overview',
  templateUrl: './realms-overview.component.html',
  styleUrls: ['./realms-overview.component.scss'],
  providers: [CompanyClientService],
})
export class RealmsOverviewComponent implements OnInit {
  displayedColumns: string[] = [
    'tenantId',
    'tenantIdentifier',
    'tenantName',
    'displayName',
    'deleteRealm',
  ];
  dataSource: CompanyAndTenantInfo[] = [];

  constructor(
    private readonly companyService: CompanyClientService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.reloadCompanyAndTenantInfos();
  }

  reloadCompanyAndTenantInfos() {
    this.companyService.getCompanyAndTenantInfos().subscribe((infos) => {
      this.dataSource = [...infos];
    });
  }

  onCreateNewRealmClicked() {
    const dialogRef = this.dialog.open(CreateRealmDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reloadCompanyAndTenantInfos();
        this.snackBar.open(
          `Realm ${result} wurde erfolgreich erstellt.`,
          'Ok',
          { duration: 5000 },
        );
      }
    });
  }

  onRowSelected(selected: CompanyAndTenantInfo) {
    this.router.navigate(['realm-details'], {
      queryParams: { tenantId: selected.tenantId },
      relativeTo: this.route,
    });
  }

  onDeleteRealm(event: any, element: CompanyAndTenantInfo) {
    event.stopPropagation();
    this.dialog.open(DeleteRealmDialogComponent, { data: element });
  }
}
