import { TenantDto } from '../../../api/gen';

export class Tenant {
  id?: string | null;
  identifier?: string | null;
  name?: string | null;
  connectionString?: string | null;
  isStandalone?: boolean;
  realmName?: string | null;

  static fromDto(dto: TenantDto): Tenant {
    return {
      id: dto.id,
      identifier: dto.identifier,
      name: dto.name,
      connectionString: dto.connectionString,
      isStandalone: dto.isStandalone,
      realmName: dto.realmName,
    } as Tenant;
  }
}
