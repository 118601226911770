/**
 * Internal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Gender = 'Male' | 'Female' | 'Diverse' | 'Undefined';

export const Gender = {
    Male: 'Male' as Gender,
    Female: 'Female' as Gender,
    Diverse: 'Diverse' as Gender,
    Undefined: 'Undefined' as Gender
};

