import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyAndTenantInfo } from '../../../core/models/company-and-tenant-info';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { TenantService } from '../../../core/services/tenant.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-delete-realm-dialog',
  templateUrl: './delete-realm-dialog.component.html',
  styleUrls: ['./delete-realm-dialog.component.scss'],
  providers: [TenantService],
})
export class DeleteRealmDialogComponent {
  protected isLoading$ = new BehaviorSubject(false);
  protected deleteFormGroup = new FormGroup({
    realm: new FormControl('', Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CompanyAndTenantInfo,
    private readonly snackBar: MatSnackBar,
    private readonly dialogRef: MatDialogRef<DeleteRealmDialogComponent>,
    private readonly tenantService: TenantService,
  ) {}

  deleteRealmClicked() {
    const realm = this.deleteFormGroup.controls.realm.value;
    const tenantId = this.data.tenantId;
    const tenantIdentifier = this.data.tenantIdentifier;
    if (realm !== tenantIdentifier) {
      this.snackBar.open(
        `Realm ${realm} and TenantIdentifier ${tenantIdentifier} do not match.`,
        'Ok',
        { duration: 5_000 },
      );
      return;
    }
    this.isLoading$.next(true);
    this.tenantService
      .deleteTenant(tenantId, tenantIdentifier)
      .subscribe(() => {
        this.snackBar.open(`Realm ${realm} deleted successfully.`, 'Ok', {
          duration: 5_000,
        });
        this.dialogRef.close();
      });
  }
}
