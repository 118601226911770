import { FeatureConfigurationService as Api } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { FeatureConfiguration } from '../models/feature-configuration';
import { UpdateFeatureConfigurationRequest } from '../models/request/update-feature-configuration-request';
import { Injectable } from '@angular/core';

@Injectable()
export class FeatureConfigurationService {
  constructor(private readonly featureConfigurationService: Api) {}

  getActiveFeatureConfigurationForTenant(
    tenantId: string,
  ): Observable<FeatureConfiguration> {
    return this.featureConfigurationService
      .internalFeatureConfigurationTenantTenantIdGet(tenantId)
      .pipe(map((dto) => FeatureConfiguration.fromDto(dto)));
  }

  updateFeatureConfiguration(
    featureConfigurationId: string,
    request: UpdateFeatureConfigurationRequest,
  ): Observable<FeatureConfiguration> {
    return this.featureConfigurationService
      .internalFeatureConfigurationFeatureConfigurationIdPatch(
        featureConfigurationId,
        request,
      )
      .pipe(map((dto) => FeatureConfiguration.fromDto(dto)));
  }
}
