import { Component } from '@angular/core';
import {
  InquiryAnalyticsDto,
  InternalInquiryAnalyticsService,
} from '../../api/gen';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
})
export class DebugComponent {
  protected currentInquiryId: string;

  protected result: InquiryAnalyticsDto;
  constructor(
    private readonly analyticsRepo: InternalInquiryAnalyticsService,
  ) {}

  getInquiryData() {
    if (this.currentInquiryId) {
      this.analyticsRepo
        .inquiryAnalyticsAnalyzeInquiryIdGet(this.currentInquiryId)
        .subscribe((x) => {
          this.result = x;
        });
    }
  }
}
