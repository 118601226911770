import { SubscriptionService as Api } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { Subscription } from '../models/subscription';
import { CreateSubscriptionInternalRequest } from '../models/request/create-subscription-internal-request';
import { Injectable } from '@angular/core';

@Injectable()
export class SubscriptionService {
  constructor(private readonly subscriptionService: Api) {}

  getSubscriptionsForAllTenants(): Observable<Subscription[]> {
    return this.subscriptionService.internalSubscriptionGet().pipe(
      map((x) => {
        return x.map((dto) => Subscription.fromDto(dto));
      }),
    );
  }

  getSubscriptions(tenantId: string): Observable<Subscription[]> {
    return this.subscriptionService
      .internalSubscriptionTenantTenantIdGet(tenantId)
      .pipe(
        map((x) => {
          return x.map((dto) => Subscription.fromDto(dto));
        }),
      );
  }

  createSubscription(
    tenantId: string,
    request: CreateSubscriptionInternalRequest,
  ): Observable<Subscription> {
    return this.subscriptionService
      .internalSubscriptionTenantTenantIdPost(
        tenantId,
        CreateSubscriptionInternalRequest.toRequest(request),
      )
      .pipe(map((dto) => Subscription.fromDto(dto)));
  }

  deleteSubscription(
    tenantId: string,
    subscriptionId: string,
  ): Observable<any> {
    return this.subscriptionService.internalSubscriptionSubscriptionIdTenantTenantIdDelete(
      subscriptionId,
      tenantId,
    );
  }
}
