<mat-sidenav-container>
  <mat-sidenav mode="side" opened>
    <mat-nav-list>
      <a
        mat-list-item
        routerLink="{{ nav.link }}"
        [routerLinkActive]="'is-nav-item-active'"
        *ngFor="let nav of navigationItems"
        >{{ nav.displayName }}</a
      >
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
