import { CompanySettingsDto } from '../../../api/gen';

export class CompanySettings {
  displayName?: string | null;
  logoUrl?: string | null;
  privacyUrl?: string | null;

  static fromDto(dto: CompanySettingsDto): CompanySettings {
    return {
      displayName: dto.displayName,
      logoUrl: dto.logoUrl,
      privacyUrl: dto.privacyUrl,
    } as CompanySettings;
  }
}
