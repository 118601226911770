import { CompanyAndTenantInfoDto } from '../../../api/gen';
import { CompanySettings } from './company-settings';
import { InitializationStatus } from './initialization-status';

export class CompanyAndTenantInfo {
  tenantId?: string | null;
  tenantIdentifier?: string | null;
  tenantName?: string | null;
  tenantConnectionString?: string | null;
  realmName?: string | null;
  companySettings?: CompanySettings;
  initializationStatus?: InitializationStatus;

  static fromDto(dto: CompanyAndTenantInfoDto): CompanyAndTenantInfo {
    return {
      tenantId: dto.tenantId,
      tenantIdentifier: dto.tenantIdentifier,
      tenantName: dto.tenantName,
      tenantConnectionString: dto.tenantConnectionString,
      realmName: dto.realmName,
      companySettings: dto.companySettings
        ? CompanySettings.fromDto(dto.companySettings)
        : null,
      initializationStatus: dto.initializationStatus
        ? InitializationStatus.fromDto(dto.initializationStatus)
        : null,
    } as CompanyAndTenantInfo;
  }
}
