import { CompanyInquiryStatisticDto } from '../../../api/gen';

export class CompanyInquiryStatistic {
  tenantId?: string | null;
  tenantIdentifier?: string | null;
  tenantName?: string | null;
  tenantConnectionString?: string | null;
  realmName?: string | null;
  inquiryLastDay?: number;
  inquiryLastWeek?: number;
  inquiryLastMonth?: number;

  static fromDto(dto: CompanyInquiryStatisticDto): CompanyInquiryStatistic {
    return {
      tenantId: dto.tenantId,
      tenantIdentifier: dto.tenantIdentifier,
      tenantName: dto.tenantName,
      tenantConnectionString: dto.tenantConnectionString,
      realmName: dto.realmName,
      inquiryLastDay: dto.inquiryLastDay,
      inquiryLastWeek: dto.inquiryLastWeek,
      inquiryLastMonth: dto.inquiryLastMonth,
    } as CompanyInquiryStatistic;
  }
}
