<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Tenant</mat-card-title>
      <mat-card-subtitle
        >Hier sind alle Detailinformationen zum Tenant</mat-card-subtitle
      >
    </mat-card-header>

    <mat-card-content>
      <table mat-table [dataSource]="dataSource" *ngIf="dataSource.length > 0">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="identifier">
          <th mat-header-cell *matHeaderCellDef>Identifier</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.identifier }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Anzeigename</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="connectionString">
          <th mat-header-cell *matHeaderCellDef>ConnectionString</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.connectionString ?? "Nicht vorhanden" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="isStandalone">
          <th mat-header-cell *matHeaderCellDef>Standalone</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.isStandalone ? "Ja" : "Nein" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="realmName">
          <th mat-header-cell *matHeaderCellDef>RealmName</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.realmName }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>
