import { Injectable } from '@angular/core';
import { CompanyService } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { CompanyAndTenantInfo } from '../models/company-and-tenant-info';
import { CompanyInquiryStatistic } from '../models/company-inquiry-statistic';

@Injectable()
export class CompanyClientService {
  constructor(private readonly companyService: CompanyService) {}

  getCompanyAndTenantInfos(): Observable<CompanyAndTenantInfo[]> {
    return this.companyService.internalCompanyGet().pipe(
      map((x) => {
        return x.map((dto) => CompanyAndTenantInfo.fromDto(dto));
      }),
    );
  }

  getCompanyInquiryStatistics(): Observable<CompanyInquiryStatistic[]> {
    return this.companyService.internalCompanyInquiryGet().pipe(
      map((x) => {
        return x.map((dto) => CompanyInquiryStatistic.fromDto(dto));
      }),
    );
  }
}
