import { Component, OnInit } from '@angular/core';
import { CompanyClientService } from '../../core/services/company-client.service';
import { CompanyInquiryStatistic } from '../../core/models/company-inquiry-statistic';

@Component({
  selector: 'app-inquiries-overview',
  templateUrl: './inquiries-overview.component.html',
  styleUrls: ['./inquiries-overview.component.scss'],
  providers: [CompanyClientService],
})
export class InquiriesOverviewComponent implements OnInit {
  displayedColumns: string[] = [
    'tenantId',
    'tenantIdentifier',
    'tenantName',
    'tenantConnectionString',
    'realmName',
    'inquiryLastDay',
    'inquiryLastWeek',
    'inquiryLastMonth',
  ];
  dataSource: CompanyInquiryStatistic[] = [];

  constructor(private readonly companyService: CompanyClientService) {}

  ngOnInit(): void {
    this.reloadCompanyAndTenantInfos();
  }

  reloadCompanyAndTenantInfos() {
    this.companyService
      .getCompanyInquiryStatistics()
      .subscribe((statistics) => {
        this.dataSource = [...statistics];
      });
  }
}
