import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionService } from '../../../core/services/subscription.service';

@Component({
  selector: 'app-confirm-delete-subscription-dialog',
  templateUrl: './confirm-delete-subscription-dialog.component.html',
  styleUrls: ['./confirm-delete-subscription-dialog.component.scss'],
  providers: [SubscriptionService],
})
export class ConfirmDeleteSubscriptionDialogComponent {
  protected subscriptionId: string;
  protected tenantId: string;
  constructor(
    private readonly subscriptionService: SubscriptionService,
    private readonly dialogRef: MatDialogRef<ConfirmDeleteSubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDeleteSubscriptionData,
  ) {
    this.subscriptionId = data.subscriptionId;
    this.tenantId = data.tenantId;
  }

  onConfirmDelete() {
    this.subscriptionService
      .deleteSubscription(this.tenantId, this.subscriptionId)
      .subscribe((_) => {
        this.dialogRef.close();
      });
  }
}

export class ConfirmDeleteSubscriptionData {
  public subscriptionId: string;
  public tenantId: string;
}
