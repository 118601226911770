import { Component, signal } from '@angular/core';
import { BrandingDataService } from 'aidar-branding';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  protected isLoading = signal(true);
  title = 'admin-dashboard';

  constructor(private readonly brandingService: BrandingDataService) {
    this.brandingService.applyBranding().subscribe({
      next: (res) => {
        this.isLoading.set(false);
      },
      error: () => {
        this.isLoading.set(false);
      },
    });
  }
}
