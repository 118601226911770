import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-heading-container',
  templateUrl: './heading-container.component.html',
  styleUrls: ['./heading-container.component.scss'],
})
export class HeadingContainerComponent {
  @Input() title: string;
  @Input() subTitle: string;

  constructor() {}
}
