<app-heading-container
  title="Tenant Details"
  subTitle="Hier können Sie alle Details zum Tenant sehen"
>
</app-heading-container>

@if (tenant(); as tenant) {
  <div class="card-container">
    <app-tenant-info [tenant]="tenant"></app-tenant-info>
  </div>

  <div class="card-container">
    <app-tenant-subscriptions [tenant]="tenant"></app-tenant-subscriptions>
  </div>

  <div class="card-container">
    <app-tenant-feature-configuration
      [tenant]="tenant"
      (featureConfigurationChanged)="onFeatureConfigurationChanged()"
    ></app-tenant-feature-configuration>
  </div>

  <div class="card-container">
    <app-tenant-branding-color [tenant]="tenant"></app-tenant-branding-color>
  </div>
}
