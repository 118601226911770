export * from './brandingColor.service';
import { BrandingColorService } from './brandingColor.service';
export * from './company.service';
import { CompanyService } from './company.service';
export * from './compositionCallbacks.service';
import { CompositionCallbacksService } from './compositionCallbacks.service';
export * from './featureConfiguration.service';
import { FeatureConfigurationService } from './featureConfiguration.service';
export * from './initializationStatus.service';
import { InitializationStatusService } from './initializationStatus.service';
export * from './internalInquiryAnalytics.service';
import { InternalInquiryAnalyticsService } from './internalInquiryAnalytics.service';
export * from './ping.service';
import { PingService } from './ping.service';
export * from './selfCheckout.service';
import { SelfCheckoutService } from './selfCheckout.service';
export * from './spryngStatusCallback.service';
import { SpryngStatusCallbackService } from './spryngStatusCallback.service';
export * from './staticTemplate.service';
import { StaticTemplateService } from './staticTemplate.service';
export * from './stripeWebhook.service';
import { StripeWebhookService } from './stripeWebhook.service';
export * from './subscription.service';
import { SubscriptionService } from './subscription.service';
export * from './tenant.service';
import { TenantService } from './tenant.service';
export * from './twilioStatusCallback.service';
import { TwilioStatusCallbackService } from './twilioStatusCallback.service';
export const APIS = [BrandingColorService, CompanyService, CompositionCallbacksService, FeatureConfigurationService, InitializationStatusService, InternalInquiryAnalyticsService, PingService, SelfCheckoutService, SpryngStatusCallbackService, StaticTemplateService, StripeWebhookService, SubscriptionService, TenantService, TwilioStatusCallbackService];
