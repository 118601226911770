/**
 * Internal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionType } from './subscriptionType';
import { PaymentProvider } from './paymentProvider';
import { FeatureConfigurationCreationDto } from './featureConfigurationCreationDto';
import { ReferrerDto } from './referrerDto';
import { PaymentInterval } from './paymentInterval';
import { BillingInformationDto } from './billingInformationDto';


export interface CreateSubscriptionInternal { 
    tenantId?: string | null;
    externalIdentifier?: string | null;
    paymentProvider?: PaymentProvider;
    paymentInterval?: PaymentInterval;
    subscriptionType?: SubscriptionType;
    billingInformation?: BillingInformationDto;
    referrer?: ReferrerDto;
    featureConfigurationCreation?: FeatureConfigurationCreationDto;
}

