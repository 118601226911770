import { CorporateIdentityConfigurationDto } from '../../../api/gen';

export class CorporateIdentityConfiguration {
  showBlankmileWatermark?: boolean;
  canEditCompanyLogo?: boolean;
  canEditCompanyColors?: boolean;
  canEditInvitationText?: boolean;
  canEditInvitationSender?: boolean;

  static fromDto(
    dto: CorporateIdentityConfigurationDto,
  ): CorporateIdentityConfiguration {
    return {
      showBlankmileWatermark: dto.showBlankmileWatermark,
      canEditCompanyLogo: dto.canEditCompanyLogo,
      canEditCompanyColors: dto.canEditCompanyColors,
      canEditInvitationText: dto.canEditInvitationText,
      canEditInvitationSender: dto.canEditInvitationSender,
    } as CorporateIdentityConfiguration;
  }
}
