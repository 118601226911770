import { Injectable } from '@angular/core';
import { TenantService as TenantApi } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { Tenant } from '../models/tenant';
import { CreateTenantRequest } from '../models/request/create-tenant-request';
import { CreateTenantStandaloneRequest } from '../models/request/create-tenant-standalone-request';

@Injectable({ providedIn: 'root' })
export class TenantService {
  constructor(private readonly tenantService: TenantApi) {}

  createNewTenant(request: CreateTenantRequest): Observable<Tenant> {
    return this.tenantService
      .tenantPost(CreateTenantRequest.toRequest(request))
      .pipe(map((dto) => Tenant.fromDto(dto)));
  }

  createNewStandaloneTenant(
    request: CreateTenantStandaloneRequest,
  ): Observable<Tenant> {
    return this.tenantService
      .tenantStandalonePost(CreateTenantStandaloneRequest.toRequest(request))
      .pipe(map((dto) => Tenant.fromDto(dto)));
  }

  getTenant(tenantId: string): Observable<Tenant> {
    return this.tenantService
      .tenantTenantIdGet(tenantId)
      .pipe(map((dto) => Tenant.fromDto(dto)));
  }

  getDefaultTenant(): Observable<Tenant> {
    return this.tenantService
      .tenantDefaultGet()
      .pipe(map((dto) => Tenant.fromDto(dto)));
  }

  getTenants(): Observable<Tenant[]> {
    return this.tenantService.tenantGet().pipe(
      map((x) => {
        return x.map((dto) => Tenant.fromDto(dto));
      }),
    );
  }

  deleteTenant(tenantId: string, tenantIdentifier: string): Observable<any> {
    return this.tenantService.tenantGdprDeleteTenantIdTenantIdentifierDelete(
      tenantId,
      tenantIdentifier,
    );
  }
}
