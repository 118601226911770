<h2 mat-dialog-title>Neuen Realm anlegen</h2>
<mat-dialog-content class="mat-typography">
  <h3>Hier können Sie einen neuen Realm erstellen</h3>

  <div *ngIf="isLoading; else settingsForm">
    <mat-spinner></mat-spinner>
  </div>

  <ng-template #settingsForm>
    <form [formGroup]="settingsFormGroup" class="create-subscription-container">
      <mat-form-field>
        <mat-label>SubscriptionType</mat-label>
        <mat-select formControlName="subscriptionType">
          <mat-option
            *ngFor="let type of subscriptionTypes"
            [value]="type.type"
          >
            {{ type.displayName }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="settingsFormGroup.controls.subscriptionType.invalid"
          >{{ requiredErrorMessage }}</mat-error
        >
      </mat-form-field>
      <mat-form-field
        *ngIf="
          settingsFormGroup.controls.subscriptionType.value ===
          SubscriptionType.Individual
        "
      >
        <mat-label>Number Of Users</mat-label>
        <input matInput type="number" formControlName="numberOfUsers" />
      </mat-form-field>
      <mat-form-field
        *ngIf="
          settingsFormGroup.controls.subscriptionType.value ===
          SubscriptionType.Individual
        "
      >
        <mat-label>Delete Data After Days</mat-label>
        <input matInput type="number" formControlName="deleteDataAfterDays" />
      </mat-form-field>
    </form>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="warn" [disabled]="isLoading">
    Abbrechen
  </button>
  <button
    mat-button
    color="primary"
    (click)="createSubscriptionClicked()"
    [disabled]="settingsFormGroup.invalid || isLoading"
  >
    Erstellen
  </button>
</mat-dialog-actions>
