<h2 mat-dialog-title>Neuen Realm anlegen</h2>
<mat-dialog-content class="mat-typography">
  <h3>Hier können Sie einen neuen Realm erstellen</h3>

  <div
    *ngIf="isLoading; else settingsForm"
    class="create-realm-loading-container"
  >
    <mat-spinner></mat-spinner>
  </div>

  <ng-template #settingsForm>
    <form [formGroup]="settingsFormGroup" class="create-realm-container">
      <mat-form-field>
        <mat-label>Realm & Tenant Identifier</mat-label>
        <input
          matInput
          type="text"
          formControlName="realmAndTenantIdentifier"
          required
        />
        <mat-error
          *ngIf="settingsFormGroup.controls.realmAndTenantIdentifier.invalid"
          >{{ requiredErrorMessage }}</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label>Firmen Anzeigename</mat-label>
        <input matInput type="text" formControlName="displayName" required />
        <mat-error *ngIf="settingsFormGroup.controls.displayName.invalid">{{
          requiredErrorMessage
        }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Admin Username</mat-label>
        <input matInput type="text" formControlName="adminUsername" required />
        <mat-error *ngIf="settingsFormGroup.controls.adminUsername.invalid">{{
          requiredErrorMessage
        }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Admin Passwort</mat-label>
        <input matInput type="text" formControlName="adminPassword" required />
        <mat-error *ngIf="settingsFormGroup.controls.adminPassword.invalid">{{
          requiredErrorMessage
        }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Admin E-Mail</mat-label>
        <input matInput type="email" formControlName="adminEmail" required />
        <mat-error *ngIf="settingsFormGroup.controls.adminEmail.invalid">{{
          getEmailErrorMessage()
        }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Admin Vorname</mat-label>
        <input matInput type="text" formControlName="adminFirstName" required />
        <mat-error *ngIf="settingsFormGroup.controls.adminFirstName.invalid">{{
          requiredErrorMessage
        }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Admin Nachname</mat-label>
        <input matInput type="text" formControlName="adminLastName" required />
        <mat-error *ngIf="settingsFormGroup.controls.adminLastName.invalid">{{
          requiredErrorMessage
        }}</mat-error>
      </mat-form-field>
    </form>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="warn" [disabled]="isLoading">
    Abbrechen
  </button>
  <button
    mat-button
    color="primary"
    (click)="createRealmClicked()"
    [disabled]="settingsFormGroup.invalid || isLoading"
  >
    Erstellen
  </button>
</mat-dialog-actions>
