import { InitializationStatusDto } from '../../../api/gen';

export class InitializationStatus {
  companySettingsInitialized?: boolean;
  inquiryReportTemplateInitialized?: boolean;

  static fromDto(dto: InitializationStatusDto): InitializationStatus {
    return {
      companySettingsInitialized: dto.companySettingsInitialized,
      inquiryReportTemplateInitialized: dto.inquiryReportTemplateInitialized,
    } as InitializationStatus;
  }
}
