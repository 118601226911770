import {
  BrandingColorService as Api,
  CreateOrUpdateBrandingColor,
} from '../../../api/gen';
import { map, Observable, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { BrandingColor, BrandingColorApiInterface } from 'aidar-branding';
import { BrandingColorDtoCasts } from '../models/branding-color';
import { TenantService } from './tenant.service';

@Injectable({ providedIn: 'root' })
export class BrandingColorService implements BrandingColorApiInterface {
  constructor(
    private readonly brandingColorService: Api,
    private readonly tenantService: TenantService,
  ) {}

  getActiveBrandingColor(): Observable<BrandingColor> {
    return this.tenantService.getDefaultTenant().pipe(
      switchMap((res) => {
        return this.brandingColorService
          .internalBrandingColorTenantIdGet(res.id)
          .pipe(map((dto) => BrandingColorDtoCasts.fromDto(dto)));
      }),
    );
  }

  getBrandingColorForTenant(tenantId: string): Observable<BrandingColor> {
    return this.brandingColorService
      .internalBrandingColorTenantIdGet(tenantId)
      .pipe(map((dto) => BrandingColorDtoCasts.fromDto(dto)));
  }

  createOrUpdateBrandingColor(
    tenantId: string,
    primaryColor: string,
    accentColor: string,
    warnColor: string,
  ): Observable<BrandingColor> {
    const request = {
      tenantId: tenantId,
      primaryColorHex: primaryColor,
      accentColorHex: accentColor,
      warnColorHex: warnColor,
    } as CreateOrUpdateBrandingColor;
    return this.brandingColorService
      .internalBrandingColorTenantIdPost(tenantId, request)
      .pipe(map((dto) => BrandingColorDtoCasts.fromDto(dto)));
  }
}
