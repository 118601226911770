<div>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Branding Color</mat-card-title>
      <mat-card-subtitle
        >Hier lässt sich die Branding Color für den Tenant bearbeiten, insofern
        die Funktionalität aktiviert ist</mat-card-subtitle
      >
    </mat-card-header>

    <mat-card-content>
      @if (isDisabled()) {
        <span
          >Das Einstellen von Farben ist für diesen Tenant nicht aktiviert</span
        >
      } @else {
        <div [formGroup]="brandingColorFormGroup" class="form-container">
          <mat-form-field>
            <mat-label>Primary Color</mat-label>
            <input matInput formControlName="primaryColor" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Accent Color</mat-label>
            <input matInput formControlName="accentColor" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Warn Color</mat-label>
            <input matInput formControlName="warnColor" />
          </mat-form-field>
        </div>
        <button mat-button color="primary" (click)="onUpdateBrandingColor()">
          Update
        </button>
      }
    </mat-card-content>
  </mat-card>
</div>
