import { Injectable } from '@angular/core';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  private static readonly BackendResource = 'aidar-backend';
  private readonly realmName: string;

  constructor(
    router: Router,
    protected readonly keycloak: KeycloakService,
  ) {
    super(router, keycloak);

    this.realmName = keycloak.getKeycloakInstance().realm;
  }

  getRealm(): string {
    return this.realmName;
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    if (!this.authenticated) {
      return false;
    }

    return this.hasRequiredRoles(route);
  }

  private hasRequiredRoles(route: ActivatedRouteSnapshot): boolean {
    const requiredRoles = route.data['roles'];
    let granted = false;

    if (!requiredRoles || requiredRoles.length === 0) {
      granted = true;
    } else {
      for (const requiredRole of requiredRoles) {
        if (this.roles.indexOf(requiredRole) > -1) {
          granted = true;
          break;
        }
      }
    }

    if (!granted) {
      console.error();
    }

    return granted;
  }
}
