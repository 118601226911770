import {
  Component,
  effect,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { Tenant } from '../../../core/models/tenant';
import { FeatureConfigurationService } from '../../../core/services/feature-configuration.service';
import { FeatureConfiguration } from '../../../core/models/feature-configuration';
import {
  MatCard,
  MatCardContent,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle,
} from '@angular/material/card';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';
import { UpdateFeatureConfigurationRequest } from '../../../core/models/request/update-feature-configuration-request';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UpdateCorporateIdentityConfigurationRequest } from '../../../core/models/request/update-corporate-identity-configuration-request';

@Component({
  selector: 'app-tenant-feature-configuration',
  standalone: true,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardSubtitle,
    MatCardContent,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatCheckbox,
    MatButton,
  ],
  templateUrl: './tenant-feature-configuration.component.html',
  styleUrl: './tenant-feature-configuration.component.scss',
  providers: [FeatureConfigurationService],
})
export class TenantFeatureConfigurationComponent implements OnInit {
  @Input() tenant: Tenant;
  @Output() featureConfigurationChanged = new EventEmitter();

  protected readonly featureConfiguration = signal<FeatureConfiguration>(null);

  protected readonly fcFormGroup = this.formBuilder.group({
    numberOfUsersIncluded: new FormControl('', [Validators.required]),
    numberOfCallsPerMonth: new FormControl(''),
    concurrentUsers: new FormControl('', [Validators.required]),
    deleteDataAfterDays: new FormControl(''),
  });

  protected readonly fcFormGroupChecks = this.formBuilder.group({
    ocrAllowed: new FormControl(false),
    recordingAllowed: new FormControl(false),
    liveChatTranslationsEnabled: new FormControl(false),
    customersCanUploadFilesDuringCall: new FormControl(false),
    showBlankmileWatermark: new FormControl(false),
    canEditCompanyLogo: new FormControl(false),
    canEditCompanyColors: new FormControl(false),
    canEditInvitationText: new FormControl(false),
    canEditInvitationSender: new FormControl(false),
  });

  constructor(
    private readonly featureConfigurationService: FeatureConfigurationService,
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
  ) {
    effect(() => {
      if (this.featureConfiguration()) {
        const fc = this.featureConfiguration();
        const fg = this.fcFormGroup.controls;
        const fgc = this.fcFormGroupChecks.controls;
        fg.numberOfUsersIncluded.setValue(fc.numberOfUsersIncluded?.toString());
        fg.numberOfCallsPerMonth.setValue(fc.numberOfCallsPerMonth?.toString());
        fg.concurrentUsers.setValue(fc.concurrentUsers?.toString());
        fg.deleteDataAfterDays.setValue(fc.deleteDataAfterDays?.toString());
        fgc.ocrAllowed.setValue(fc.ocrAllowed);
        fgc.recordingAllowed.setValue(fc.recordingAllowed);
        fgc.liveChatTranslationsEnabled.setValue(
          fc.liveChatTranslationsEnabled,
        );
        fgc.customersCanUploadFilesDuringCall.setValue(
          fc.customersCanUploadFilesDuringCall,
        );
        fgc.showBlankmileWatermark.setValue(
          fc.corporateIdentityConfiguration.showBlankmileWatermark,
        );
        fgc.canEditCompanyLogo.setValue(
          fc.corporateIdentityConfiguration.canEditCompanyLogo,
        );
        fgc.canEditCompanyColors.setValue(
          fc.corporateIdentityConfiguration.canEditCompanyColors,
        );
        fgc.canEditInvitationText.setValue(
          fc.corporateIdentityConfiguration.canEditInvitationText,
        );
        fgc.canEditInvitationSender.setValue(
          fc.corporateIdentityConfiguration.canEditInvitationSender,
        );
      }
    });
  }

  ngOnInit(): void {
    this.reloadFeatureConfiguration();
  }

  onUpdateFeatureConfigurationClick() {
    const fc = this.featureConfiguration();
    const fg = this.fcFormGroup.controls;
    const fgc = this.fcFormGroupChecks.controls;
    const request = {
      featureConfigurationId: fc.id,
      numberOfUsersIncluded: this.getNumber(fg.numberOfUsersIncluded.value),
      numberOfCallsPerMonth: this.getNumber(fg.numberOfCallsPerMonth.value),
      concurrentUsers: this.getNumber(fg.concurrentUsers.value),
      deleteDataAfterDays: this.getNumber(fg.deleteDataAfterDays.value),
      ocrAllowed: fgc.ocrAllowed.value,
      recordingAllowed: fgc.recordingAllowed.value,
      liveChatTranslationsEnabled: fgc.liveChatTranslationsEnabled.value,
      customersCanUploadFilesDuringCall:
        fgc.customersCanUploadFilesDuringCall.value,
      corporateIdentityConfiguration: {
        showBlankmileWatermark: fgc.showBlankmileWatermark.value,
        canEditCompanyLogo: fgc.canEditCompanyLogo.value,
        canEditCompanyColors: fgc.canEditCompanyColors.value,
        canEditInvitationText: fgc.canEditInvitationText.value,
        canEditInvitationSender: fgc.canEditInvitationSender.value,
      } as UpdateCorporateIdentityConfigurationRequest,
    } as UpdateFeatureConfigurationRequest;
    this.featureConfigurationService
      .updateFeatureConfiguration(fc.id, request)
      .subscribe((result) => {
        this.snackBar.open(
          `Feature configuration with id ${result.id} was updated.`,
          'Ok',
          { duration: 3000 },
        );
        this.reloadFeatureConfiguration();
      });
  }

  onDiscardFeatureConfigurationClick() {
    this.reloadFeatureConfiguration();
  }

  private reloadFeatureConfiguration() {
    this.featureConfigurationService
      .getActiveFeatureConfigurationForTenant(this.tenant.id)
      .subscribe((fc) => {
        this.featureConfiguration.set(fc);
        this.featureConfigurationChanged.emit();
      });
  }

  private getNumber(n: string): number | null {
    if (!n) {
      return null;
    }

    if (n.length === 0) {
      return null;
    }

    const num = Number(n);
    if (isNaN(num)) {
      return null;
    }

    return num;
  }
}
