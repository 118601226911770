import { CreateTenantStandalone } from '../../../../api/gen';

export class CreateTenantStandaloneRequest {
  identifier?: string | null;
  name?: string | null;
  connectionString?: string | null;
  realmName?: string | null;
  adminUsername?: string | null;
  adminPassword?: string | null;
  adminEmail?: string | null;
  adminFirstName?: string | null;
  adminLastName?: string | null;

  static toRequest(req: CreateTenantStandaloneRequest): CreateTenantStandalone {
    return {
      identifier: req.identifier,
      name: req.name,
      connectionString: req.connectionString,
      realmName: req.realmName,
      adminUsername: req.adminUsername,
      adminPassword: req.adminPassword,
      adminEmail: req.adminEmail,
      adminFirstName: req.adminFirstName,
      adminLastName: req.adminLastName,
    } as CreateTenantStandalone;
  }
}
