<h2 mat-dialog-title>Realm löschen</h2>
<mat-dialog-content class="mat-typography">
  <span>
    Soll der Realm <b>{{ this.data.tenantIdentifier }}</b> mit der id
    <b>{{ this.data.tenantId }}</b> wirklich gelöscht werden?
  </span>
  <br />
  <span
    >Verifizieren Sie die Löschanfrage indem Sie den Realm Namen erneut
    eingeben.</span
  >

  <div *ngIf="isLoading$ | async; else settingsForm">
    <mat-spinner></mat-spinner>
  </div>

  <ng-template #settingsForm>
    <form [formGroup]="deleteFormGroup" class="delete-realm-container">
      <mat-form-field>
        <mat-label>Realm</mat-label>
        <input matInput type="text" formControlName="realm" required />
        <mat-error *ngIf="deleteFormGroup.controls.realm.invalid"
          >Realm wird benötigt</mat-error
        >
      </mat-form-field>
    </form>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="isLoading$ | async">
    Abbrechen
  </button>
  <button
    mat-button
    color="warn"
    (click)="deleteRealmClicked()"
    [disabled]="deleteFormGroup.invalid || (isLoading$ | async)"
  >
    Löschen
  </button>
</mat-dialog-actions>
