import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-hangfire',
  standalone: true,
  templateUrl: './hangfire.component.html',
  styleUrl: './hangfire.component.scss',
  imports: [],
})
export class HangfireComponent {
  protected url: string;

  constructor(protected sanitizer: DomSanitizer) {
    this.url = environment.hangfirePath;
  }
}
