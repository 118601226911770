<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Aktive Feature Konfiguration</mat-card-title>
      <mat-card-subtitle
        >Hier sind alle aktiven Features in der derzeit aktiven
        Tenant-Subscription</mat-card-subtitle
      >
    </mat-card-header>

    <mat-card-content>
      <h3>Aktiv: {{ this.featureConfiguration()?.id }}</h3>
      <div class="content-container">
        <form [formGroup]="fcFormGroup" class="container-form-inputs">
          <mat-form-field>
            <mat-label>Number of users included</mat-label>
            <input
              matInput
              type="number"
              formControlName="numberOfUsersIncluded"
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Number of calls per month</mat-label>
            <input
              matInput
              type="number"
              formControlName="numberOfCallsPerMonth"
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Concurrent users</mat-label>
            <input matInput type="number" formControlName="concurrentUsers" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Delete data after days</mat-label>
            <input
              matInput
              type="number"
              formControlName="deleteDataAfterDays"
            />
          </mat-form-field>
        </form>
        <div class="container-checkbox-inputs" [formGroup]="fcFormGroupChecks">
          <mat-checkbox formControlName="ocrAllowed">OCR Allowed</mat-checkbox>
          <mat-checkbox formControlName="recordingAllowed"
            >Recording Allowed</mat-checkbox
          >
          <mat-checkbox formControlName="liveChatTranslationsEnabled"
            >Live Chat</mat-checkbox
          >
          <mat-checkbox formControlName="customersCanUploadFilesDuringCall"
            >Customer Upload Files During Call</mat-checkbox
          >
          <mat-checkbox formControlName="showBlankmileWatermark"
            >Show blankmile. Watermark</mat-checkbox
          >
          <mat-checkbox formControlName="canEditCompanyLogo"
            >Can Edit Company Logo</mat-checkbox
          >
          <mat-checkbox formControlName="canEditCompanyColors"
            >Can Edit Company Colors</mat-checkbox
          >
          <mat-checkbox formControlName="canEditInvitationText"
            >Can Edit Invitation Text</mat-checkbox
          >
          <mat-checkbox formControlName="canEditInvitationSender"
            >Can Edit Invitation Sender</mat-checkbox
          >
        </div>
      </div>
      <div class="actions-container">
        <button
          mat-button
          color="primary"
          (click)="onUpdateFeatureConfigurationClick()"
        >
          Update
        </button>
        <button
          mat-button
          color="warn"
          (click)="onDiscardFeatureConfigurationClick()"
        >
          Discard
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
