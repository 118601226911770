import {
  PaymentInterval,
  PaymentProvider,
  SubscriptionDto,
} from '../../../api/gen';
import { FeatureConfiguration } from './feature-configuration';
import { BillingInformation } from './billing-information';
import { Referrer } from './referrer';

export class Subscription {
  id?: string;
  externalIdentifier?: string | null;
  paymentProvider?: PaymentProvider;
  paymentInterval?: PaymentInterval;
  subscriptionType?: SubscriptionType;
  startedAt?: string | null;
  endedAt?: string | null;
  cancelledAt?: string | null;
  featureConfiguration?: FeatureConfiguration;
  billingInformation?: BillingInformation;
  referrer?: Referrer;

  static fromDto(dto: SubscriptionDto): Subscription {
    return {
      id: dto.id,
      externalIdentifier: dto.externalIdentifier,
      paymentProvider: dto.paymentProvider,
      paymentInterval: dto.paymentInterval,
      subscriptionType: dto.subscriptionType,
      startedAt: dto.startedAt,
      endedAt: dto.endedAt,
      cancelledAt: dto.cancelledAt,
      featureConfiguration: dto.featureConfiguration
        ? FeatureConfiguration.fromDto(dto.featureConfiguration)
        : null,
      billingInformation: dto.billingInformation
        ? BillingInformation.fromDto(dto.billingInformation)
        : null,
      referrer: dto.referrer ? Referrer.fromDto(dto.referrer) : null,
    } as Subscription;
  }
}

export type SubscriptionType =
  | 'Trial'
  | 'Starter'
  | 'Pro'
  | 'Ultimate'
  | 'Individual';

export const SubscriptionType = {
  Trial: 'Trial' as SubscriptionType,
  Starter: 'Starter' as SubscriptionType,
  Pro: 'Pro' as SubscriptionType,
  Ultimate: 'Ultimate' as SubscriptionType,
  Individual: 'Individual' as SubscriptionType,
};
