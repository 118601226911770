import { FeatureConfigurationDto } from '../../../api/gen';
import { CorporateIdentityConfiguration } from './corporate-identity-configuration';

export class FeatureConfiguration {
  id?: string;
  numberOfUsersIncluded?: number;
  numberOfCallsPerMonth?: number | null;
  concurrentUsers?: number;
  deleteDataAfterDays?: number | null;
  ocrAllowed?: boolean;
  recordingAllowed?: boolean;
  liveChatTranslationsEnabled?: boolean;
  customersCanUploadFilesDuringCall?: boolean;
  corporateIdentityConfiguration?: CorporateIdentityConfiguration;

  static fromDto(dto: FeatureConfigurationDto): FeatureConfiguration {
    return {
      id: dto.id,
      numberOfUsersIncluded: dto.numberOfUsersIncluded,
      numberOfCallsPerMonth: dto.numberOfCallsPerMonth,
      concurrentUsers: dto.concurrentUsers,
      deleteDataAfterDays: dto.deleteDataAfterDays,
      ocrAllowed: dto.ocrAllowed,
      recordingAllowed: dto.recordingAllowed,
      liveChatTranslationsEnabled: dto.liveChatTranslationsEnabled,
      customersCanUploadFilesDuringCall: dto.customersCanUploadFilesDuringCall,
      corporateIdentityConfiguration: dto.corporateIdentityConfiguration
        ? CorporateIdentityConfiguration.fromDto(
            dto.corporateIdentityConfiguration,
          )
        : null,
    } as FeatureConfiguration;
  }
}
