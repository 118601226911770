import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './core/init/keycloak-init.factory';
import { interceptorProviders } from './core/interceptors/interceptors';
import { HttpClientModule } from '@angular/common/http';
import { SideNavComponent } from './side-nav/side-nav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RealmsOverviewComponent } from './realms/realms-overview/realms-overview.component';
import { InquiriesOverviewComponent } from './inquiries/inquiries-overview/inquiries-overview.component';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { HeadingContainerComponent } from './common/heading-container/heading-container.component';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { environment } from '../environments/environment';
import { ApiModule, Configuration, ConfigurationParameters } from '../api/gen';
import { CreateRealmDialogComponent } from './realms/dialogs/create-realm-dialog/create-realm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DebugComponent } from './debug/debug.component';
import { StaticTemplateOverviewComponent } from './templates/static-template-overview/static-template-overview.component';
import { RealmDetailsOverviewComponent } from './realms/realm-details/realm-details-overview/realm-details-overview.component';
import { TenantInfoComponent } from './realms/realm-details/tenant-info/tenant-info.component';
import { TenantSubscriptionsComponent } from './realms/realm-details/tenant-subscriptions/tenant-subscriptions.component';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDeleteSubscriptionDialogComponent } from './realms/dialogs/confirm-delete-subscription-dialog/confirm-delete-subscription-dialog.component';
import { CreateSubscriptionDialogComponent } from './realms/dialogs/create-subscription-dialog/create-subscription-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { DeleteRealmDialogComponent } from './realms/dialogs/delete-realm-dialog/delete-realm-dialog.component';
import { TenantFeatureConfigurationComponent } from './realms/realm-details/tenant-feature-configuration/tenant-feature-configuration.component';
import { TenantBrandingColorComponent } from './realms/realm-details/tenant-branding-color/tenant-branding-color.component';
import { BrandingColorService } from './core/services/branding-color.service';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.apiPath,
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    RealmsOverviewComponent,
    InquiriesOverviewComponent,
    HeadingContainerComponent,
    CreateRealmDialogComponent,
    DebugComponent,
    StaticTemplateOverviewComponent,
    RealmDetailsOverviewComponent,
    TenantInfoComponent,
    TenantSubscriptionsComponent,
    ConfirmDeleteSubscriptionDialogComponent,
    CreateSubscriptionDialogComponent,
    DeleteRealmDialogComponent,
  ],
  imports: [
    ApiModule.forRoot(apiConfigFactory),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    KeycloakAngularModule,
    MatSidenavModule,
    MatListModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatDialogModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatIconModule,
    MatSelectModule,
    TenantFeatureConfigurationComponent,
    TenantBrandingColorComponent,
    MenuBarComponent,
  ],
  providers: [
    {
      provide: 'BrandingColorApi',
      useClass: BrandingColorService,
    },
    interceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
