import {
  CreateSubscriptionInternal,
  PaymentInterval,
  PaymentProvider,
  SubscriptionType,
} from '../../../../api/gen';
import { BillingInformation } from '../billing-information';
import { Referrer } from '../referrer';
import { FeatureConfigurationCreation } from '../feature-configuration-creation';

export class CreateSubscriptionInternalRequest {
  tenantId?: string | null;
  externalIdentifier?: string | null;
  paymentProvider?: PaymentProvider;
  paymentInterval?: PaymentInterval;
  subscriptionType?: SubscriptionType;
  billingInformation?: BillingInformation;
  referrer?: Referrer;
  featureConfigurationCreation?: FeatureConfigurationCreation;

  static toRequest(
    request: CreateSubscriptionInternalRequest,
  ): CreateSubscriptionInternal {
    return {
      tenantId: request.tenantId,
      externalIdentifier: request.externalIdentifier,
      paymentProvider: request.paymentProvider,
      paymentInterval: request.paymentInterval,
      subscriptionType: request.subscriptionType,
      billingInformation: request.billingInformation
        ? BillingInformation.toDto(request.billingInformation)
        : null,
      referrer: request.referrer ? Referrer.toDto(request.referrer) : null,
      featureConfigurationCreation: request.featureConfigurationCreation
        ? FeatureConfigurationCreation.toDto(
            request.featureConfigurationCreation,
          )
        : null,
    } as CreateSubscriptionInternal;
  }
}
