import { Component } from '@angular/core';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
  navigationItems: NavigationLink[] = [
    { link: '/', displayName: 'Tenants' },
    { link: '/inquiries', displayName: 'Anfragen' },
    { link: '/static-templates', displayName: 'Static Templates' },
    { link: '/debug', displayName: 'Inquiry-Infos' },
    { link: '/hangfire', displayName: 'Hangfire' },
  ];
}

export interface NavigationLink {
  link: string;
  displayName: string;
}
