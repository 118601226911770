export const environment = {
  production: false,
  apiPath:
    'https://blankmile-api-testing.kindplant-4a458331.northeurope.azurecontainerapps.io',
  hangfirePath:
    'https://blankmile-api-testing.kindplant-4a458331.northeurope.azurecontainerapps.io/hangfire',
  storagePath: 'https://blankmiletesting.blob.core.windows.net',
  defaultKeycloakTenant: 'madtech',
  keycloak: {
    url: 'https://auth.testing.blankmile.com',
    realm: 'madtech',
    clientId: 'aidar-helper-client',
  },
};
