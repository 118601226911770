import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { Subscription } from '../../../core/models/subscription';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDeleteSubscriptionData,
  ConfirmDeleteSubscriptionDialogComponent,
} from '../../dialogs/confirm-delete-subscription-dialog/confirm-delete-subscription-dialog.component';
import {
  CreateSubscriptionDialogComponent,
  CreateSubscriptionDialogData,
} from '../../dialogs/create-subscription-dialog/create-subscription-dialog.component';
import { Tenant } from '../../../core/models/tenant';

@Component({
  selector: 'app-tenant-subscriptions',
  templateUrl: './tenant-subscriptions.component.html',
  styleUrls: ['./tenant-subscriptions.component.scss'],
  providers: [SubscriptionService],
})
export class TenantSubscriptionsComponent implements OnInit {
  @Input() tenant: Tenant;

  displayedColumns: string[] = [
    'id',
    'externalIdentifier',
    'paymentProvider',
    'paymentInterval',
    'subscriptionType',
    'startedAt',
    'endedAt',
    'cancelledAt',
    'cancel',
  ];
  dataSource: Subscription[] = [];

  constructor(
    private readonly subscriptionService: SubscriptionService,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.refreshSubscriptions();
  }

  addSubscription() {
    const dialogRef = this.dialog.open(CreateSubscriptionDialogComponent, {
      data: { tenantId: this.tenant.id } as CreateSubscriptionDialogData,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshSubscriptions();
    });
  }

  onDeleteSubscription(element: Subscription) {
    const dialogRef = this.dialog.open(
      ConfirmDeleteSubscriptionDialogComponent,
      {
        data: {
          tenantId: this.tenant.id,
          subscriptionId: element.id,
        } as ConfirmDeleteSubscriptionData,
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      this.refreshSubscriptions();
    });
  }

  private refreshSubscriptions() {
    this.subscriptionService
      .getSubscriptions(this.tenant.id)
      .subscribe((subscriptions) => {
        this.dataSource = [...subscriptions];
      });
  }
}
