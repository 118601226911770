import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { RealmsOverviewComponent } from './realms/realms-overview/realms-overview.component';
import { InquiriesOverviewComponent } from './inquiries/inquiries-overview/inquiries-overview.component';
import { DebugComponent } from './debug/debug.component';
import { StaticTemplateOverviewComponent } from './templates/static-template-overview/static-template-overview.component';
import { RealmDetailsOverviewComponent } from './realms/realm-details/realm-details-overview/realm-details-overview.component';
import { HangfireComponent } from './hangfire/hangfire.component';

const routes: Routes = [
  { path: '', component: RealmsOverviewComponent, canActivate: [AuthGuard] },
  { path: 'realm-details', component: RealmDetailsOverviewComponent },

  {
    path: 'inquiries',
    component: InquiriesOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'static-templates',
    component: StaticTemplateOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'debug',
    component: DebugComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hangfire',
    component: HangfireComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
