<app-heading-container
  title="Tenant Übersicht"
  subTitle="Hier können Sie Informationen über alle Tenants sehen"
>
  <button mat-flat-button color="primary" (click)="onCreateNewRealmClicked()">
    Neuen Realm anlegen
  </button>
</app-heading-container>

<mat-card>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="tenantId">
      <th mat-header-cell *matHeaderCellDef>Tenant Id</th>
      <td mat-cell *matCellDef="let element">{{ element?.tenantId }}</td>
    </ng-container>

    <ng-container matColumnDef="tenantIdentifier">
      <th mat-header-cell *matHeaderCellDef>Tenant Identifier</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.tenantIdentifier }}
      </td>
    </ng-container>

    <ng-container matColumnDef="tenantName">
      <th mat-header-cell *matHeaderCellDef>Tenant Name</th>
      <td mat-cell *matCellDef="let element">{{ element?.tenantName }}</td>
    </ng-container>

    <ng-container matColumnDef="tenantConnectionString">
      <th mat-header-cell *matHeaderCellDef>Tenant Conn-Str.</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.tenantConnectionString }}
      </td>
    </ng-container>

    <ng-container matColumnDef="realmName">
      <th mat-header-cell *matHeaderCellDef>Realm Name</th>
      <td mat-cell *matCellDef="let element">{{ element?.realmName }}</td>
    </ng-container>

    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef>Display Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.companySettings?.displayName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="logoUrl">
      <th mat-header-cell *matHeaderCellDef>Logo URL</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.companySettings?.logoUrl }}
      </td>
    </ng-container>

    <ng-container matColumnDef="privacyUrl">
      <th mat-header-cell *matHeaderCellDef>Privacy URL</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.companySettings?.privacyUrl }}
      </td>
    </ng-container>

    <ng-container matColumnDef="companySettingsInitialized">
      <th mat-header-cell *matHeaderCellDef>Company Settings Initialized</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.initializationStatus?.companySettingsInitialized }}
      </td>
    </ng-container>

    <ng-container matColumnDef="inquiryReportTemplateInitialized">
      <th mat-header-cell *matHeaderCellDef>Templates Initialized</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.initializationStatus?.inquiryReportTemplateInitialized }}
      </td>
    </ng-container>

    <ng-container matColumnDef="deleteRealm">
      <th mat-header-cell *matHeaderCellDef>GDPR-Delete Realm</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-button
          color="warn"
          (click)="onDeleteRealm($event, element)"
        >
          Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="onRowSelected(row)"
    ></tr>
  </table>
</mat-card>
