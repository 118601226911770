import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { MatToolbar } from '@angular/material/toolbar';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
  standalone: true,
  imports: [MatToolbar, MatButton],
})
export class MenuBarComponent {
  constructor(private readonly keycloak: KeycloakService) {}

  onLogoutClicked() {
    this.keycloak.logout().then((r) => console.log('User logged out: ' + r));
  }
}
