import { CreateTenant } from '../../../../api/gen';

export class CreateTenantRequest {
  identifier?: string | null;
  name?: string | null;
  realmName?: string | null;
  adminUsername?: string | null;
  adminPassword?: string | null;
  adminEmail?: string | null;
  adminFirstName?: string | null;
  adminLastName?: string | null;

  static toRequest(req: CreateTenantRequest): CreateTenant {
    return {
      identifier: req.identifier,
      name: req.name,
      realmName: req.realmName,
      adminUsername: req.adminUsername,
      adminPassword: req.adminPassword,
      adminEmail: req.adminEmail,
      adminFirstName: req.adminFirstName,
      adminLastName: req.adminLastName,
    } as CreateTenant;
  }
}
