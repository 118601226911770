<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>Subscriptions</mat-card-title>
        <mat-card-subtitle
          >Hier sind alle Detailinformationen zu den aktiven
          Subscriptions</mat-card-subtitle
        >
        <button mat-flat-button color="primary" (click)="addSubscription()">
          Hinzufügen
        </button>
      </mat-card-title-group>
    </mat-card-header>

    <mat-card-content>
      <table
        mat-table
        [dataSource]="dataSource"
        *ngIf="dataSource.length > 0; else noSubscriptionFound"
      >
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="externalIdentifier">
          <th mat-header-cell *matHeaderCellDef>Externe Id</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.externalIdentifier }}
          </td>
        </ng-container>

        <ng-container matColumnDef="paymentProvider">
          <th mat-header-cell *matHeaderCellDef>Provider</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.paymentProvider }}
          </td>
        </ng-container>

        <ng-container matColumnDef="paymentInterval">
          <th mat-header-cell *matHeaderCellDef>Interval</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.paymentInterval }}
          </td>
        </ng-container>

        <ng-container matColumnDef="subscriptionType">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.subscriptionType }}
          </td>
        </ng-container>

        <ng-container matColumnDef="startedAt">
          <th mat-header-cell *matHeaderCellDef>StartedAt</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.startedAt | date: "medium" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="endedAt">
          <th mat-header-cell *matHeaderCellDef>EndedAt</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.endedAt | date: "medium" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="cancelledAt">
          <th mat-header-cell *matHeaderCellDef>CancelledAt</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.cancelledAt | date: "medium" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="cancel">
          <th mat-header-cell *matHeaderCellDef>Beenden</th>
          <td mat-cell *matCellDef="let element">
            <button
              *ngIf="!element?.cancelledAt"
              mat-icon-button
              color="warn"
              (click)="onDeleteSubscription(element)"
            >
              <mat-icon>cancel</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <ng-template #noSubscriptionFound>
        <div class="no-subscription">
          <span class="no-subscription-text">Keine Subscription vorhanden</span>
        </div>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
