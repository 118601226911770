import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TenantHeaderInterceptorService } from './tenant-header-interceptor.service';

export const interceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TenantHeaderInterceptorService,
    multi: true,
  },
];
