/**
 * Internal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InitializationStatusDto } from './initializationStatusDto';
import { CompanySettingsDto } from './companySettingsDto';


export interface CompanyAndTenantInfoDto { 
    tenantId?: string | null;
    tenantIdentifier?: string | null;
    tenantName?: string | null;
    tenantConnectionString?: string | null;
    realmName?: string | null;
    companySettings?: CompanySettingsDto;
    initializationStatus?: InitializationStatusDto;
}

